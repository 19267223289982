<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <CInput
                    placeholder="user@example.com"
                    autocomplete="email"
                    v-model="data.email"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-user"
                    /></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="data.password"
                  >
                    <template #prepend-content
                      ><CIcon name="cil-lock-locked"
                    /></template>
                  </CInput>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton
                        color="primary"
                        class="px-4"
                        v-on:click="login(data)"
                        >Login</CButton
                      >
                    </CCol>
                    <CCol col="6" class="text-right">
                      <CButton color="link" class="px-0" href="/#/pages/forgot"
                        >Forgot password?</CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <Enter2FAModal
      :showModal="isShowVerify2FAModal"
      @close-modal="isShowVerify2FAModal = false"
      @2fa-verify-code="onVerify2FA"
    />
  </div>
</template>

<script>
import { ACTION_LOGIN, ACTION_FETCH_PROFILE } from '@/store/actions.type';
import { MUTATION_SET_AUTH } from '@/store/mutations.type';

import Enter2FAModal from '@/components/modals/Enter2FAModal';
import UserInternalProxy from '@/proxies/userinternal.proxy';

export default {
  name: 'Login',
  components: {
    Enter2FAModal,
  },
  data() {
    return {
      loading: false,
      data: {
        email: '',
        password: '',
      },
      isShowVerify2FAModal: false,
      token: '',
    };
  },
  methods: {
    login(data) {
      this.$store.dispatch(ACTION_LOGIN, data).then((res) => {
        if (res.two_factor_enabled) {
          this.token = res.token;
          this.isShowVerify2FAModal = true;
        } else {
          this.$store.dispatch(ACTION_FETCH_PROFILE).then(() => {
            this.$router.push({ name: 'dashboard.index' });
          });
        }
      });
    },
    onVerify2FA({ code }) {
      UserInternalProxy.verify2FA(this.token, { code }).then(() => {
        this.$store.commit(MUTATION_SET_AUTH, this.token);
        this.isShowVerify2FAModal = false;
        this.$toast.success(`Verify 2FA successfully`, {
          duration: 5000,
        });
        this.$store.dispatch(ACTION_FETCH_PROFILE).then(() => {
          this.$router.push({ name: 'dashboard.index' });
        });
      });
    },
  },
};
</script>
